import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";

import imgC1 from "../../assets/image/png/hospitals/hero1.png";
import imgC2 from "../../assets/image/png/hospitals/hero2.png";
import imgC3 from "../../assets/image/png/hospitals/hero3.png";
import imgC4 from "../../assets/image/png/hospitals/hero4.png";
import { Link } from "gatsby";

const SectionStyled = styled(Section)``;

const SingleImage = styled(Box)`
  display: flex;
  flex-direction: column;
  img {
    padding-bottom: 15px;
    padding-right: 15px;
    border-radius: 8px;
  }
`;

const Content2 = ({ title, subtitle }) => {
  return (
    <>
      {/* <!-- Content2 section --> */}
      <SectionStyled pt="0!important">
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0">
              <Box className="d-flex">
                <SingleImage>
                  <img
                    src={imgC1}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-once="true"
                  />
                  <img
                    src={imgC2}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="500"
                    data-aos-once="true"
                  />
                </SingleImage>
                <SingleImage>
                  <img
                    src={imgC3}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="1000"
                    data-aos-once="true"
                  />
                  <img
                    src={imgC4}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="1500"
                    data-aos-once="true"
                  />
                </SingleImage>
              </Box>
            </Col>
            <Col lg="6" md={9}>
              <div>
                {/* <Title>
                  See the world &amp;{" "}
                  <br className="d-none d-sm-block d-md-none d-xl-block" />
                  spend less always.
                </Title> */}
                <Title>{title}</Title>
                <Text>{subtitle}</Text>
                <a href="https://api.whatsapp.com/send/?phone=917611078653&text=I%27m+interested+in+a+treatment+plan">
                  <Button my={3}>Free treatment plan</Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content2;

import React from "react";
import Hero from "../sections/landing6/Content2";
import Clients from "../sections/landing1/Clients";
import Feature from "../sections/landing3/Feature";
import Content1 from "../sections/landing1/Content1";
import Content2 from "../sections/landing1/Content2";
import Numbers from "../sections/landing7/Fact";
// import Content1Black from "../sections/landing3/Content1";
import Testimonial from "../sections/landing2/Testimonial";
import Process from "../sections/landing4/Feature";
import Offices from "../sections/common/Offices";
import CTA from "../sections/landing4/CTA";
import PageWrapper from "../components/PageWrapper";



const Nigeria = () => {
  return (
    <>
      <PageWrapper footerDark>
        <div style={{marginTop: "80px"}}></div>
        <Hero title="Medical surgeries in India from Nigeria" subtitle="Visit Indian hospitals for affordable spine, ortho and cancer treatments under world-renowned doctors"/>
        <Clients />
        <Content2 title="Affordable for every patient" subtitle="Travel, accomodation and better facilities at affordable rates for anyone in Nigeria" />
        <Feature />
        {/* <Content1Black /> */}
        <Content1 title="Zero support charges from patients" subtitle="You don’t pay anything extra for our services. You get treatment plans at negotiated rates directly from the hospital." />
        <Numbers />
        <Testimonial />
        <Process />
        <Offices />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default Nigeria;

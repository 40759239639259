import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Text } from "../../components/Core";
import iconVideo from "../../assets/image/png/icons/video.png"
import iconTicket from "../../assets/image/png/icons/ticket.png"
import iconHotel from "../../assets/image/png/icons/hotel.png"
import iconTransport from "../../assets/image/png/icons/transport.png"
import iconTranslator from "../../assets/image/png/icons/translator.png"
import iconFood from "../../assets/image/png/icons/food.png"

const FeatureCard = ({
  color = "primary",
  // iconName,
  iconImage,
  title,
  children,
  ...rest
}) => (
  <Box pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      {/* <i className={`icon ${iconName}`}></i> */}
      <img src={iconImage} alt="" />
    </Box>
    <div className="text-center">
      <Text
        color="dark"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75} color="darkShade">
        {children}
      </Text>
    </div>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative pt-5" bg="light">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="secondary"
              // iconName="icon-phone-2"
              iconImage={iconVideo}
              title="Video calls with Doctor"
            >
              Online consultation with our Indian surgeons before travelling
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              // iconName="icon-airplane-2"
              iconImage={iconTicket}
              title="Free medical visa support"
            >
              Get support for all the formalities involved in travelling
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="secondary"
              // iconName="icon-airplane-2"
              iconImage={iconHotel}
              title="Top rated Hotels/Apartments"
            >
              Only world class hospitals with NABH and JCI accreditations
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="primary"
              // iconName="icon-voice-recognition-2"
              iconImage={iconTransport}
              title="Free Airport pick-up & drop"
            >
              Get verified, safe travel arrangements while in India
            </FeatureCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="warning"
              // iconName="icon-bookmark-2-2"
              iconImage={iconTranslator}
              title="24x7 Translator "
            >
              Communicate easily with doctors and others with friendly translators
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="secondary"
              // iconName="icon-infinite"
              iconImage={iconFood}
              title="Support for food"
            >
              Order meals prepared to your taste and preferences when you need them
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;

import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  
  padding-bottom: 50px;
  padding-top: 50px;
  @media ${device.md} {
    padding-top: 50px;
    padding-bottom: 90px;
  }
`;

// const FeatureCard = ({ iconImage, title, children, ...rest }) => (
//     <Box {...rest}>
//       <Box mb={[3, 3, 3, 4]} pb={2}>
//         <img src={iconImage} alt="" />
//       </Box>
//       <Box>
//         <Title variant="card" fontSize="24px" mb={3}>
//           {title}
//         </Title>
//         <Text variant="small">{children}</Text>
//       </Box>
//     </Box>
//   );

const TitleContainer = styled(Box)`
  position: relative;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 42%;
    background: ${({ theme }) => theme.colors.border};
    margin-top: 0.5px;
    display: block;

    @media ${device.md} {
      width: 40%;
      display: block;
    }
    @media ${device.lg} {
      width: 72%;
    }
    @media ${device.xl} {
      width: 60%;
    }
  }
`;

const Offices = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="light">
        <Container>
            <TitleContainer mb={"40px"}>
                <Text fontSize="24px" as="h4" className="">
                Our offices in Africa{" "}
                </Text>
            </TitleContainer>
        </Container>
      <Container>
    {/*     <Row className="justify-content-center">
            <Col sm="6" className="mb-5 mb-lg-4">
            <FeatureCard title="Dar Es Salaam">
            Visit our partner clinics in Africa to discuss your conditions and recommended treatments
            </FeatureCard>
            </Col>
            <Col sm="6" className="mb-5 mb-lg-4">
            <FeatureCard title="Get treatment plan from hospitals">
            We send you treatment plans from hospitals based on your doctor’s recommendations.
            </FeatureCard>
            </Col>
        </Row> */}
        <Row className="pt-4">
          <Col sm={6} className="mb-5 mb-md-0">
            <Box pr={4}>
              <Title variant="card" fontSize="32px" color="dark" mb="20px!important">
                Dar Es Salaam
              </Title>
              <Text color="darkShade">
              Husain Specialized Polyclinic Limited, <br className="d-none d-sm-block" />{" "}
              Kimweri Avenue, Msasani, Dar-es-Salaam
              </Text>
            </Box>
          </Col>
          <Col sm={6} className="mb-4 mb-md-0">
            <Box>
              <Title variant="card" fontSize="32px" color="dark" mb="20px!important">
                Nigeria
              </Title>
              <Text color="darkShade">
              #47, Yussuf Street, Off Opesa, <br className="d-none d-sm-block" />{" "}
              Opeka, Ipaja, Lagos, Nigeria. 
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Offices;
